<template>
  <v-card class="elevation-0">
    <v-card-title>
      JABATAN
      <v-spacer></v-spacer>

      <!--      v-model="search"-->
      <!--      @keyup.enter="searchOnEnter"-->
      <v-text-field
        v-on:keyup.enter="searchOnEnter"
        v-on:blur="searchOnEnter"
        append-icon="mdi-magnify"
        hint="Kode, Deskripsi (press ENTER to search)"
        label="Search"
      ></v-text-field>
      <!--          single-line-->
      <!--          hide-details-->
    </v-card-title>
    <!--    :search="search"-->
    <v-data-table
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="fJenisJabatansFiltered"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages = totalPaginationPages"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
            v-model="multiSelect"
            :label="multiSelect ? 'Multi Select' : 'Single Select'"
            x-small
            class="pa-3"
          ></v-switch>
          <v-btn
            fab
            dark
            color="red accent-4"
            x-small
            :disabled="!multiSelect"
            class="mr-2"
            @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            fab
            dark
            color="green"
            x-small
            @click="showDialogNew"
            :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        {{ lookupFDivision(item.fdivisionBean) }}
      </template>
      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip :color="getColorStatusActive(item.statusActive)" dark small>
          {{ item.statusActive == true ? "Aktif" : "-" }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="showDialogEdit(item)" icon :disabled="multiSelect">
          <v-icon small color="warning"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn @click="deleteDialogShow(item)" icon :disabled="multiSelect">
          <v-icon small color="red accent-4"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <!--    @page-count="pageCount = $event"-->

    <v-container>
      <v-row justify="end" align="center">
        <v-col cols="4" md="2" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per page"
          ></v-select>
        </v-col>
        <v-col cols="10" md="9" sm="8" align="right">
          <v-pagination
            v-model="currentPage"
            :length="totalPaginationPages"
            total-visible="8"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <delete-dialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></delete-dialog>

    <FJenisJabatanDialog
      :formMode="formMode"
      ref="refFormDialog"
      @eventFromFormDialogNew="saveDataNew"
      @eventFromFormDialogEdit="saveDataEdit"
    ></FJenisJabatanDialog>

    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
  
  <script>
import FJenisJabatanService from "@/services/apiservices/f-jenis-jabatan-service";
import FDivisionService from "@/services/apiservices/f-division-service";

import DeleteDialog from "@/components/utils/DeleteConfirmDialog";
import FJenisJabatanDialog from "./FJenisJabatanDialog";
import FormMode from "@/models/form-mode";
import FJenisJabatan from "@/models/f-jenis-jabatan";

export default {
  components: { FJenisJabatanDialog, DeleteDialog },
  data() {
    return {
      snackbar: false,
      snackBarMesage: "",

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: "",
      headers: [
        {
          text: "Kode1",
          align: "start",
          sortable: false,
          value: "kode1",
        },
        { text: "Jenis Jabatan", value: "description" },
        { text: "Dinas/OPD", value: "fdivisionBean" },
        { text: "Aktif", value: "statusActive" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      fJenisJabatans: [new FJenisJabatan(0, "", "")],
      itemsFDivision: [
        { id: 1, kode1: "", description: "" },
        { id: 2, kode1: "", description: "" },
      ],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFJenisJabatan();
      },
    },
    pageSize: {
      handler: function (value) {
        const refreshData = this.currentPage == 1;
        this.currentPage = 1;
        if (refreshData) {
          console.log("Change PageSize " + value);
          this.fetchFJenisJabatan();
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      },
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fJenisJabatansFiltered() {
      return this.fJenisJabatans;
    },
  },
  methods: {
    searchOnEnter(event) {
      if (this.search !== event.target.value) {
        //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFJenisJabatan();
      }
    },
    fetchParent() {
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )

      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }
    },
    fetchFJenisJabatan() {
      FJenisJabatanService.getAllFJenisJabatanContaining(
        this.currentPage,
        this.pageSize,
        "id",
        "DESC",
        this.search
      ).then(
        (response) => {
          // console.log(" >>> " + response.data.items)
          const { items, totalPages } = response.data;
          this.fJenisJabatans = items;
          this.totalPaginationPages = totalPages;
          // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
        },
        (error) => {
          console.log(error.response);
          if (error.response.statusCode === 401) {
            this.snackBarMesage = "Anda tidak mempunyai Akses!!";
            this.snackbar = true;
          }
        }
      );
    },
    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },

    showDialogNew() {
      this.itemSelectedIndex = -1;
      const itemModified = Object.assign({}, "");
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM;
      this.$refs.refFormDialog.showDialog(
        this.itemSelectedIndex,
        itemModified,
        this.itemsFDivision
      );
    },
    saveDataNew(itemFromRest) {
      this.itemSelected = itemFromRest;
      this.closeDialog();
      /**
       * Setiap data baru harus terlihat
       */
      // this.fJenisJabatans.push(this.itemSelected)
      // this.fJenisJabatans = [itemFromRest].concat(this.fJenisJabatans)
      // this.fJenisJabatans.unshift(itemFromRest)
      this.fetchFJenisJabatan();
    },

    showDialogEdit(item) {
      this.itemSelectedIndex = this.fJenisJabatansFiltered.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFormDialog.showDialog(
        this.itemSelectedIndex,
        itemModified,
        this.itemsFDivision
      );
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      Object.assign(
        this.fJenisJabatans[this.itemSelectedIndex],
        this.itemSelected
      );
      this.closeDialog();
    },

    deleteDialogShow(item) {
      // console.log(item)
      this.itemSelectedIndex = this.fJenisJabatansFiltered.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.username
      );
    },
    deleteDialogMultiShow() {
      if (this.multiSelect === true) {
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(
          this.selectedItems,
          `${this.selectedItems.length} items selected`
        );
      }
    },
    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.fJenisJabatans[this.itemSelectedIndex];
      FJenisJabatanService.deleteFJenisJabatan(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fJenisJabatans.splice(this.itemSelectedIndex, 1);
          this.closeDialog();
        },
        (error) => {
          console.log(error);
          this.snackBarMesage = "gagal hapus (digunakan oleh data anakk)";
          this.snackbar = true;
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      // console.log(okeMessage)
      if (items.length > -1) {
        let itemIds = [];
        for (let i = 0; i < items.length; i++) {
          itemIds.push(items[i].id);
        }
        // console.log("Item idex: " + itemIds)

        FJenisJabatanService.deleteAllFJenisJabatan(itemIds).then(
          (response) => {
            if (response.data) {
              for (let i = 0; i < items.length; i++) {
                if (
                  !response.data.includes(items[i].id) ||
                  response.data.length === 0
                ) {
                  const index = this.fJenisJabatansFiltered.indexOf(items[i]);
                  this.fJenisJabatans.splice(index, 1);
                }
                if (response.data.length > 0) {
                  this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`;
                  this.snackbar = true;
                }

                console.log(
                  "Undeleted Items: " + response.data + " = " + items[i].id
                );
              }
              this.closeDialog();
            }
          },
          (error) => {
            console.log("error " + error);
          }
        );

        // console.log(items)
        // FJenisJabatanService.deleteAllFJenisJabatans()
      }
    },
    closeDialog() {
      // this.myConfirmDialog = false
      this.formMode = "";
      this.$refs.refDeleteConfirmDialog.setDialogState(false);
      this.$refs.refFormDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FJenisJabatan());
        this.itemSelectedIndex = -1;
      });
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id == fdivisionBean);
      if (str.length > 0) {
        return str[0].description;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    this.fetchFJenisJabatan();
    this.fetchParent();
  },
};
</script>
  
  <style scoped>
</style>